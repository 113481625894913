$(document).on('turbolinks:load', function() {
  $(".complete-bucket-btn").unbind().on('click', function(e) {
    $.ajax({
      type: "GET",
      url: "/cms/mandje",
      data: {kind: "all"}
    })
    .done(function(data){
      $('#bucketModal').html(data);
    });
  });

  $(".add-bucket-item").unbind().on( "click", function() {
    addBucket($(this));
  });

  $(".remove-bucket-item").unbind().on( "click", function() {
    removeBucket($(this));
  });
});

function addBucket(btn) {
  $.ajax({
    type: "POST",
    url: "/cms/mandje",
    data: {bucketable_id: btn.data("bucketId"), bucketable_type: btn.data("bucketClass")}
  })
  .done(function(data){
    btn.toggleClass("add-bucket-item remove-bucket-item").toggleClass("btn-primary btn-danger");
    btn.children( ".fas" ).toggleClass("fa-plus fa-minus");
    btn.unbind().on( "click", function() {
      removeBucket(btn);
    });
  });
}

function removeBucket(btn) {
  $.ajax({
    type: "DELETE",
    url: "/cms/mandje/delete_ajax",
    data: {bucketable_id: btn.data("bucketId"), bucketable_type: btn.data("bucketClass")}
  })
  .done(function(data){
    btn.toggleClass("add-bucket-item remove-bucket-item").toggleClass("btn-primary btn-danger");
    btn.children( ".fas" ).toggleClass("fa-plus fa-minus");
    btn.unbind().on( "click", function() {
      addBucket(btn)
    });
  });
}