$(document).on('turbolinks:load', function() {
  if(!$('.add-highlight-item').length && !$('.remove-highlight-item').length) return;
  $(".add-highlight-item").on( "click", function() {
    addHighlight($(this));
  });

  $(".remove-highlight-item").on( "click", function() {
   removeHighlight($(this));
  });
});

function addHighlight(btn) {
  $.ajax({
    type: "POST",
    url: "/cms/uitgelicht",
    data: {highlightable_id: btn.data("highlightId"), highlightable_type: btn.data("highlightClass")}
  })
  .done(function(data){
    btn.toggleClass("add-highlight-item remove-highlight-item").toggleClass("btn-primary btn-danger");
    btn.children( ".fas" ).toggleClass("fa-plus fa-minus");
    btn.unbind().on( "click", function() {
      removeHighlight(btn);
    });  
  });
}

function removeHighlight(btn) {
  $.ajax({
    type: "DELETE",
    url: "/cms/uitgelicht/delete_ajax",
    data: {highlightable_id: btn.data("highlightId"), highlightable_type: btn.data("highlightClass")}
  })
  .done(function(data){
    btn.toggleClass("add-highlight-item remove-highlight-item").toggleClass("btn-primary btn-danger");
    btn.children( ".fas" ).toggleClass("fa-plus fa-minus");
    btn.unbind().on( "click", function() {
      addHighlight(btn)
    });    
  });
}