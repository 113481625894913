import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search", "results", "addable" ]

  searchTags(e) {
    if(e.key == "Enter") {
      e.preventDefault();
      var value = this.searchValue.toLowerCase()
      var tag = document.querySelector(`.add-tag[data-name=${value}]`)
      if(tag) {
        tag.click()
      } else {
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch('/cms/kernwoorden', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          },
          method: "POST",
          body: JSON.stringify({a: value})
        }).then(r => r.json())
          .then(r => {
            var templateTag = document.querySelector('#tagCreate');
            var tagItem = document.importNode(templateTag.content, true);
            tagItem.querySelector("span").textContent = r.name;
            var input = tagItem.querySelector(".remove-tag");
            var inputTag = input.querySelector(".tag-field");
            inputTag.setAttribute('data-id', r.id);
            inputTag.setAttribute('value', r.id);
            this.addableTarget.appendChild(tagItem)
            var a = JSON.parse(this.data.get("current").replace(/'/g, '"'));
            a.push(r.id);
            this.data.set("current", `[${a}]`)
          })
      }


    } else {
      if (this.searchValue.length > 2) {
        fetch(`/cms/kernwoorden?q=${this.searchValue}&exclude=${this.data.get("current")}`)
          .then(r => r.json())
          .then(r => {
            if(r.length > 0) {
              this.resultsTarget.innerHTML = ""
              var templateTags = document.querySelector('#tagItems');
              var tagItems = document.importNode(templateTags.content, true);
              var templateTag = document.querySelector('#tagItem');
              r.forEach(tag => {
                  let tagItem = document.importNode(templateTag.content, true);
                  tagItem.querySelector("span").textContent = tag.name;
                  let input = tagItem.querySelector("button");
                  input.setAttribute('data-id', tag.id);
                  input.setAttribute('data-name', tag.name.toLowerCase());
                  tagItems.appendChild(tagItem)
                }
              )
              this.resultsTarget.appendChild(tagItems)
            } else {
              this.resultsTarget.innerHTML = "Geen kernwoorden"
            }
          })
      } else {
        this.resultsTarget.innerHTML = ""
      }
    }

  }

  addTag(e) {
    var templateTag = document.querySelector('#tagCreate');
    var tagItem = document.importNode(templateTag.content, true);
    var value = e.currentTarget.getAttribute('data-id');
    tagItem.querySelector("span").textContent = e.currentTarget.getAttribute('data-name');
    tagItem.querySelector("input").value = value
    this.addableTarget.appendChild(tagItem)
    e.currentTarget.remove()
    var a = JSON.parse(this.data.get("current").replace(/'/g, '"'));
    a.push(value);
    this.data.set("current", `[${a}]`)
  }

  removeTag(e) {
    var a = JSON.parse(this.data.get("current").replace(/'/g, '"'));
    a.splice( a.indexOf(parseInt(e.currentTarget.querySelector("input").value)), 1 );
    this.data.set("current", `[${a}]`)
    e.currentTarget.remove()
  }

  get searchValue() {
    return this.searchTarget.value
  }
}