$(document).on('turbolinks:load', function() {
  // if(!$('.bucket-item-field').length && !$('.bucket-item-assoc').length) return;
  setRemoveBucketItem();
  singleBucketItem();
  removeBucketAssoc();
  associationBucketItems();
  setFieldsForForm();
});

function setFieldsForForm() {
  $('form').on('click', '.remove_fields', function(e) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    e.preventDefault();
  });

  $('form').on('click', '.add_fields', function(e) {
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).before($(this).data('fields').replace(regexp, time))
    e.preventDefault();
    setRemoveBucketItem();
    singleBucketItem();
    removeBucketAssoc();
    associationBucketItems();
  });
}

function setRemoveBucketItem() {
  $(".remove-item").unbind().on('click', function(e) {
    var form_element = $(this).nextAll(".bucket-item-field")
    var image = form_element.data("items") == "Image"
    if (image) {
      var target = form_element.prevAll(".selected-image")
    } else {
      var target = form_element.prevAll(".selected-item")
    }
    var field_data = target.prevAll(".bucket-field-data")
    field_data.val("");
    if (image) {
      target.css('background-image', 'url()')
    } else {
      target.text("Selecteer iets")
    }
    $(this).remove()
  });
}

function removeBucketAssoc() {
  $(".remove-assoc").unbind().on('click', function(e) {
    var assoc_item = $(this).parent(".assoc-item")
    assoc_item.remove()
  });
}

function associationBucketItems(){
  $('.bucket-item-assoc').unbind().on('click', function(e) {
    var form_element = $(this)
    var target = form_element.prevAll(".selected-box")
    var field_data = target.prevAll(".bucket-field-data")
    $.ajax({
      type: "GET",
      url: "/cms/mandje",
      data: {kind: $(this).data("items"), content: $(this).data("content")}
    })
    .done(function(data){
      var bucket = form_element.nextAll(".bucket-display")
      bucket.html(data);
      $(".bucket-item").on('click', function(e) {
        var assoc_id = $(this).data("item")
        if (!$("input[name='"+target.data("name")+"["+target.data("field")+"][]"+"'][value='"+assoc_id+"']").length) {
          target.append($("<div class='assoc-item' data-id="+assoc_id+"><input multiple='multiple' class='bucket-field-data' value='"+assoc_id+"' type='hidden' name='"+target.data("name")+"["+target.data("field")+"][]'>"+$(this).data("src")+"<div class='fas fa-times remove-assoc'></div></div>"))
          removeBucketAssoc();
        }
      });
      $(".close-bucket").on('click', function(e) {
        bucket.empty();
      });
    });
  });
}

function singleBucketItem() {
  $('.bucket-item-field').unbind().on('click', function(e) {
    var form_element = $(this)
    var image = form_element.data("items") == "Image"
    if (image) {
      var target = form_element.prevAll(".selected-image")
    } else {
      var target = form_element.prevAll(".selected-item")
    }
    var field_data = target.prevAll(".bucket-field-data")
    $.ajax({
      type: "GET",
      url: "/cms/mandje",
      data: {kind: $(this).data("items"), content: $(this).data("content")}
    })
    .done(function(data){
      var bucket = form_element.nextAll(".bucket-display")
      bucket.html(data);
      $(".bucket-item").on('click', function(e) {
        field_data.val($(this).data("item"));
        if (image) {
          target.css('background-image', 'url(' + $(this).data("src") + ')')
        } else {
          target.text($(this).data("src"))
        }
        bucket.empty();
        if(!target.nextAll(".remove-item").length) {
          target.after($("<div class='fas fa-trash remove-item'></div>"))
        }
        setRemoveBucketItem()
      });
      $(".close-bucket").on('click', function(e) {
        bucket.empty();
      });
    });
  });
}