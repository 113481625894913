$(document).on('turbolinks:load', function() {
  var sortable_tables = $('.sortable');
  if (sortable_tables.length > 0) {
    sortable_tables.each(function(){
      var table = $(this);
      return $(this).sortable({
        axis: 'y',
        items: '.item',
        cursor: 'move',

        update: function(e, ui) {
          return $.ajax({
            type: 'PUT',
            url: '/cms/change_position',
            data: { class_name: table.data('className'), item: {item_id: ui.item.data('itemid'), row_order_position: ui.item.index() } }
          }).done(function() {
            var index = 1;
            $(table).find('.td-position').each(function(){
              $(this).text(index);
              index++;
            })
          });
        }
      });
    })
  }
});